<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Source Transport
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-alert outlined type="info" dismissible prominent icon="mdi-transfer">
        UnStruct provides the flexibility to define custom transport layers for your data sources, accommodating a wide range of data movement and management needs. You can specify the transport mechanism best suited for your operational requirements, including but not limited to:
        <br>
        <ul>
          <li><strong>REST API:</strong> For web-based data interactions and retrievals.</li>
          <li><strong>Syslog:</strong> For centralized logging and event management.</li>
          <li><strong>Hive:</strong> For accessing data stored in Hadoop.</li>
          <li><strong>S3:</strong> For utilizing AWS's scalable storage service.</li>
          <li><strong>Kafka:</strong> For real-time data processing and streaming.</li>
        </ul>
        <br>
        This capability ensures that UnStruct seamlessly integrates with your existing data infrastructure, enhancing data accessibility and operational efficiency.
      </v-alert>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card class="pa-3 mb-3">
                <!-- Card Header -->
                <v-card-title>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-menu bottom left>
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="createEditShow(item)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>

                <!-- Card Subtitle / Description -->
                <v-card-subtitle>{{ item.description }}</v-card-subtitle>

                <!-- Card Content, More Details can be added here -->
                <!-- Can add more details if needed -->

              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/data/source/transport/DeleteDialog.vue"
import NewEditSheet from "@/data/source/transport/NewEditSheet.vue"

export default {
  name: "SourceTransportTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
  },
  data() {
    return {
      headers: [],
    }
  },

  computed: {
    ...mapFields("sourceTransport", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("sourceTransport", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
